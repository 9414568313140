import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";

import VoucherModal from "./voucher";
import { useAsyncSetState } from "use-async-setstate";
import { getProductsQuery, getProductsResult } from "../logic/products";
import { setFormData, getFormData } from "../manager/form";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "../utils/constants";

const ProductSelector = ({ onSelectProduct, buildingCode }) => {
  const [showVoucher, setShowVoucher] = useAsyncSetState(false);
  const {
    voucherCode = "",
    serviceAvailability,
    selectedProducts: formProducts,
    isNewConnection,
    locationId,
    accessCircuitIds,
  } = getFormData();
  const [selectedProducts, setSelectedProducts] = useAsyncSetState([]);
  const [index, setIndex] = useAsyncSetState(0);
  const variables = {
    buildingCode,
    voucherCode,
    showHidden: true,
  };

  const pageInfoQuery = useQuery(getProductsQuery, {
    variables,
  });

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const products = getProductsResult(pageInfoQuery);
  let accessCircuitRef = locationId;
  if (locationId.startsWith("VLC")) {
    accessCircuitRef = accessCircuitIds[0];
  }
  if (!accessCircuitRef.startsWith("VAC")) {
    throw new Error("Invalid access circuit ref");
  }
  const planProducts = (products || []).filter(
    (product) => product.hidden === false && product?.type === "internet"
  ).map((product) => ({
    ...product,
    orderItemOpts: {
      accessCircuitRef,
    },
  }));

  const hardwareProductsDisplay = products.filter(({type}) => type === "hardware");
  const addOnProductsDisplay = products.filter(({type}) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(type));

  const monthly = selectedProducts
    .filter(({ type }) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(type))
    .filter(({ once }) => !once)
    .reduce((prev, value) => prev + Number(value.value), 0);
  
  let initialPayment = selectedProducts.reduce(
    (prev, value) => prev + Number(value.value),
    0
  );

  let newConnectionProduct;

  if (isNewConnection) {
    newConnectionProduct = products.find((product) => product?.onlyNewConnection === true);

    if (newConnectionProduct) {
      setFormData({ newConnectionProduct });
      if ((newConnectionProduct?.value || 0) > 0 && !isNaN(newConnectionProduct?.value)) {
        initialPayment += newConnectionProduct?.value;
      }
    }
  }

  return (
    <Container className="my-2 mx-auto py-5 product-purchase-container">
      {showVoucher && (
        <VoucherModal
          setVoucher={async (data) => {
            return pageInfoQuery.refetch({
              buildingCode,
              voucherCode,
            });
          }}
          onClose={async () => setShowVoucher(false)}
        />
      )}
      <Row>
        <Col>
          <div className="main-text text-center">{"Select Plan"}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          {!voucherCode ? (
            <a
              onClick={async () => setShowVoucher(true)}
              className="pointer-cursor sub-text text-center mx-auto d-block voucher-link"
            >
              {"click here to apply voucher"}
            </a>
          ) : (
            <a
              onClick={async () => {
                setFormData({ voucherCode: "" });
                return pageInfoQuery.refetch();
              }}
              className="pointer-cursor sub-text text-center mx-auto d-block voucher-link"
            >
              {"click here to remove voucher"}
            </a>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel
            interval={null}
            activeIndex={index}
            nextIcon={
              <i
                className="fas fa-angle-right carousel-control"
                onClick={async () => {
                  const newIndex =
                    index < planProducts.length - 1 ? index + 1 : 0;
                  await setIndex(newIndex);
                  const product = planProducts[newIndex];
                  let selectEvents = product?.selectEventsActivate || [];
                  let eventProducts = selectEvents.map((se) => (products || []).find((p) => p.name === se));
                  return setSelectedProducts(eventProducts);
                }}
              />
            }
            prevIcon={
              <i
                className="fas fa-angle-left carousel-control"
                onClick={async () => {
                  const newIndex =
                    index > 0 ? index - 1 : (planProducts || []).length - 1;
                  await setIndex(newIndex);
                  const product = planProducts[newIndex];
                  let selectEvents = product?.selectEventsActivate || [];
                  let eventProducts = selectEvents.map((se) => (products || []).find((p) => p.name === se));
                  return setSelectedProducts(eventProducts);
                }}
              />
            }
          >
            {(planProducts || []).map((product) => {
              const [wholeNumber = "00", decimal = "00"] = `${
                product?.value || ""
              }`.split(".");
              return (
                <Carousel.Item key={product?.id}>
                  <Col xs={10} sm={8} lg={6} xl={4} className="d-block mx-auto">
                    <div className="p-0 mb-1 mx-auto mt-0 border-0 d-block">
                      <div className="plan-item text-center">
                        <div className="plan-title font-primary-bold">
                          {product.name || ""}
                        </div>
                        <div className="plan-price pt-3">
                          <span className="price-value">
                            {`$${wholeNumber}`}
                          </span>
                          <span className="small-price">{`.${decimal}`}</span>
                          <span className="price-freq">{"/mo."}</span>
                        </div>
                        <div className="plan-subtitle">
                          {product.description || ""}
                        </div>
                        <div className="plan-subtitle bold">
                          {"No lock-in contract"}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col xs={8} id="review" className={`mx-auto`}>
          <div className="product-addons">
            <Fragment>
              <Addons
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                hardwareProductsDisplay={hardwareProductsDisplay}
                addOnProductsDisplay={addOnProductsDisplay}
                products={products}
              />
            </Fragment>
            {(newConnectionProduct?.value || 0) > 0 && <div className="bold text-center">
              {`${newConnectionProduct?.description || ""} ($${newConnectionProduct?.value})`}
            </div>}
            <hr/>
            <div className="bold text-center">
              {"Initial Payment (Including First Month)"}
            </div>
            <div className="bold text-center" style={{ fontSize: 35 }}>
              {`$${initialPayment + (planProducts[index]?.value || 0)}`}
            </div>
            <div className="bold text-center">{"Monthly"}</div>
            <div className="bold text-center" style={{ fontSize: 35 }}>
              {`$${monthly + (planProducts[index]?.value || 0)}`}
            </div>
            <Row>
              <Col>
                <Button
                  variant="yellow btn-wide vw-button mx-auto my-3 d-block py-2 px-5"
                  onClick={async () => {
                    const newProducts = selectedProducts.filter(
                      (sp) => sp.hidden === true
                    );

                    const newSelectedProducts = [
                      planProducts[index],
                      ...selectedProducts,
                      ...newProducts.filter(np => !selectedProducts.find(sp => sp.id === np.id)) // Makes sure there is no duplicate
                    ]
                  
                    setFormData({
                      selectedProducts: newSelectedProducts,
                      newConnectionProduct
                    });
                    await onSelectProduct(planProducts[index]);
                  }}
                >
                  {"Confirm"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="minimum text-center">
                {`The plan is month to month and automatically renewed.`}
                <div>
                  {`Total minimum cost is $${
                    initialPayment + (planProducts[index]?.value || 0)
                  } inc. GST.`}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const Addons = ({
  selectedProducts,
  setSelectedProducts,
  hardwareProductsDisplay,
  addOnProductsDisplay,
  products
}) => {

  const handleToggleChange = async (product) => {
    if (selectedProducts.find((sp) => sp.id === product.id)) {
      return setSelectedProducts(
        selectedProducts.filter((sp) => sp.id !== product.id)
      );
    }

    const selectEvents = (product?.selectEventsActivate || []).map((se) => products.find((p) => p.name === se));

    let newSelectedProducts = selectedProducts.concat([product, ...selectEvents]);
    newSelectedProducts = newSelectedProducts.filter((nsp, i) => newSelectedProducts.findIndex((p) => nsp?.id === p?.id && !!nsp?.id) === i);

    return setSelectedProducts(newSelectedProducts);
  }

  return (
    <Fragment>
      {hardwareProductsDisplay.length > 0 && (
        <div className="title bold text-center">{"Hardware"}</div>
      )}
      <Row className="mb-3">
        {hardwareProductsDisplay.length > 0 &&
          hardwareProductsDisplay.map((product) => (
            <Col key={product.id} xs={12} md={6}>
              <div className="custom-control my-3 custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${product.id}`}
                  checked={
                    selectedProducts.filter((sp) => sp.id === product.id)
                      .length > 0
                  }
                  onClick={() => handleToggleChange(product)}
                />
                <label
                  className="custom-control-label bold"
                  htmlFor={`${product.id}`}
                >
                  {product.name}
                  <span className="ml-2">
                    {`($${parseFloat(product.value)}/Once)`}
                  </span>
                </label>
              </div>
            </Col>
          ))}
      </Row>
      {addOnProductsDisplay.length > 0 && (
        <div className="title bold text-center">{"Additional Addons"}</div>
      )}
      <Row className="mb-3">
        {addOnProductsDisplay.length > 0 &&
          addOnProductsDisplay.map((product) => (
            <Col key={product.id} xs={12} md={6}>
              <div
                key={product.id}
                className="custom-control my-3 custom-switch"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${product.id}`}
                  checked={
                    selectedProducts.filter((sp) => sp.id === product.id)
                      .length > 0
                  }
                  onClick={() => handleToggleChange(product)}
                />
                <label
                  className="custom-control-label bold"
                  htmlFor={`${product.id}`}
                >
                  {product.name}
                  <span className="ml-2">
                    {`($${parseFloat(product.value)}/Monthly)`}
                  </span>
                </label>
              </div>
            </Col>
          ))}
      </Row>
    </Fragment>
  );
}

export default ProductSelector;
