import React, {Fragment, useRef} from "react";
import {Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import {useQuery} from "@apollo/client";
import root from "window-or-global";

import Purchase from "../components/section/purchase";
import ProductSelector from "../components/bootstrap/product-selector";
import SEO from "../components/section/seo";
import Main from "../components/main";
import Header from "../components/section/header";
import Footer from "../components/section/footer";
import {searchServiceAvailabilityQuery} from "../components/logic/address";
import {setFormData} from "../components/manager/form";
import {CIS_LINK} from "../components/utils/constants";

const Index = (props) => {
  return (
    <Main>
      <DataLayer {...props} />
    </Main>
  );
}

const DataLayer = (props) => {
  const params = new URLSearchParams(root.location?.search);
  const locationId = params.get("locationId");
  
  const query = useQuery(searchServiceAvailabilityQuery, {
    variables: {
      address: locationId,
    },
  });

  if (query.loading) {
    return <Fragment />;
  }

  if (!query.loading && !(locationId)) {
    return <React.Fragment />;
  }

  const selectedAddress = query?.data?.classMethods?.Address?.searchAddressServiceability[0];
  
  return (
    <Register building={selectedAddress.building} selectedAddress={selectedAddress} {...props} />
  )
}


const Register = ({building, selectedAddress}) => {
  const myRef = useRef(null);
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: false,
    productSelector: true,
    purchase: false,
  });

  const cisLink = building?.cisLink || CIS_LINK;
  if (selectedAddress) {
    setFormData(selectedAddress);
  } else {
    return window.location = "/";
  }

  return (
    <Fragment>
      <SEO title="SpeedyTel - Internet Provider">
        <meta name="description" content="SpeedyTel - Register" />
      </SEO>
      <Header />
      <div className="plan-section register d-flex align-items-center" ref={myRef}>
        {step.productSelector && <>
          <ProductSelector
            cisLink={cisLink || ""}
            buildingCode={building?.code|| ""}
            onSelectProduct={async (product) => {
              await setStep({
                productSelector: false,
                addressSelector: false,
                purchase: true,
              });
              return setProduct(product);
            }}
          />
        </>}

        {step.purchase &&
          <Container className="mx-auto">
            <Purchase product={selectedProduct} selectProduct={(data) => setProduct(data)} onSuccess={async () => {
              return setStep({
                productSelector: false,
                addressSelector: true,
                purchase: false,
              });
            }} />
          </Container>
        }
      </div>
      <Footer />
    </Fragment>
  );
}

export default Index;
